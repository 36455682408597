import React from 'react';
import { AbsoluteCenter, Spinner, Box, Text } from '@chakra-ui/react';
import CustomerPerformance from '../../../components/CustomerPerformance';

const Funding = ({ performanceData, loading }) => {
  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <>
      <Box lineHeight={{ base: '7', xl: '42px' }} mb="5">
        <Text
          fontSize={{ base: '20px', md: '25px', lg: '36px' }}
          color="black.main"
          fontWeight="500"
        >
          Funding
        </Text>
        <Text fontSize={{ base: '14px', lg: '16px' }} color="gray.main">
          Something about funding
        </Text>
      </Box>
      <CustomerPerformance data={performanceData} />
    </>
  );
};

export default Funding;
