import React from "react";
import {
  Box,
  Text,
} from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label
} from "recharts";
const TwelveFinance = () => {
  const data = [
    { month: "Jan", withTwelve: 0, withoutTwelve: 0 },
    { month: "Feb", withTwelve: 100, withoutTwelve: 100 },
    { month: "Mar", withTwelve: 150, withoutTwelve: 150 },
    { month: "Apr", withTwelve: 180, withoutTwelve: 180 },
    { month: "May", withTwelve: 220, withoutTwelve: 220 },
    { month: "Jun", withTwelve: 270, withoutTwelve: 250 },
    { month: "Jul", withTwelve: 320, withoutTwelve: 250 },
    { month: "Aug", withTwelve: 380, withoutTwelve: 240 },
    { month: "Sep", withTwelve: 450, withoutTwelve: 220 },
    { month: "Oct", withTwelve: 560, withoutTwelve: 190 },
    { month: "Nov", withTwelve: 660, withoutTwelve: 170 },
    { month: "Dec", withTwelve: 750, withoutTwelve: 150 }
  ];
  const formatYAxisTick = (value) => {
    return `$${value}k`;
  };
  return (
    <>
      <Box
        backgroundColor="#fff"
        padding={{ base: "14px 16px", lg: "20px 24px" }}
        rounded="md"
        shadow={"box"}
      >
        <Box marginBottom={"30px"}>
          <Text
            fontSize={{ base: "14px", lg: "16px" }}
            color={"gray.200"}
            fontWeight={"700"}
            className="mb-1"
          >
            TWELVE IMPACT
          </Text>
          <Text color={"gray.main"} fontSize={"14px"} fontWeight={"400"}>
            See how Twelve impacted and is predicted to impact your growth.
          </Text>
        </Box>

        <ResponsiveContainer width={"99%"} height={311}>
          <LineChart data={data}>
            <CartesianGrid stroke="#E0E8F0" vertical={false} />
            <XAxis
              stroke="#E0E8F0"
              dataKey="month"
              height={80}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                dy: 30,
                fontSize: 14
              }}
            />
            <YAxis
              axisLine={false}
              tickFormatter={formatYAxisTick}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: 14
              }}
            />
            <Tooltip />
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="end"
              iconType="circle"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="withTwelve"
              stroke="#2084F9"
              strokeWidth={2}
            />
            <Line
              dot={false}
              type="monotone"
              dataKey="withoutTwelve"
              stroke="#F322F8"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default TwelveFinance;
