import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart
} from "recharts";
import ChurnGraph from "./ChurnGraph";
const Customers = () => {
  const data = [
    { month: "Jan", actual: 1, projections: 1 },
    { month: "Feb", actual: 1, projections: 1 },
    { month: "Mar", actual: 3, projections: 4 },
    { month: "Apr", actual: 3, projections: 4 },
    { month: "May", actual: 5, projections: 5 },
    { month: "June", actual: 5, projections: 6 },
    { month: "July", actual: 7, projections: 8 },
    { month: "Aug", actual: 8, projections: 9 },
    { month: "Sep", actual: 10, projections: 12 },
    { month: "Oct", actual: 12, projections: 13 },
    { month: "Nov", actual: 13, projections: 14 },
    { month: "Dec", actual: 14, projections: 16 }
  ];

  return (
    <>
      <section>
        <Box lineHeight={{ base: "7" }} mb="5" mt={"100px"}>
          <Text
            fontSize={{ base: "20px", md: "25px", lg: "36px" }}
            color="black.main"
            fontWeight="500"
          >
            Customers
          </Text>
          <Text fontSize={{ base: "14px", lg: "16px" }} color="gray.main">
            So far this month you gained 1,500 installs, 1,200 free trials and
            800 new paying users. Right on!
          </Text>
        </Box>

        <ChurnGraph />
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(5, 1fr)"
          }}
          gap={6}
        >
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"20px"}
            >
              Active Paying
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              1,071
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"20px"}
            >
              New Customers
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              30
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"20px"}
            >
              Churn (n)
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              160
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"20px"}
            >
              Avg Lifetime
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              7.3
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"20px"}
            >
              Net. Rev. Retention %
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              57%
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
        </Grid>
        <Text
          fontSize={"16px"}
          color="black.main"
          fontWeight="700"
          paddingTop={"34px"}
        >
          Active Paying
        </Text>
        <ResponsiveContainer width={"99%"} height={340}>
          <BarChart data={data}>
            <CartesianGrid stroke="#E0E8F0" vertical={false} />
            <XAxis
              dataKey="month"
              stroke="#E0E8F0"
              height={80}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                dy: 30,
                fontSize: 12
              }}
            />
            <YAxis
              axisLine={false}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: 12
              }}
            />
            {/* <Tooltip /> */}
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="end"
              iconType="circle"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            <Bar dataKey="actual" stackId="stack" fill="#2084F9" barSize={40} />
            <Bar
              dataKey="projections"
              stackId="stack"
              fill="#E0E8F0"
              barSize={40}
            />
          </BarChart>
        </ResponsiveContainer>
      </section>
    </>
  );
};

export default Customers;
