import { Image, Box } from '@chakra-ui/react';
import React from 'react';

const ChurnGraph = () => {
  const data = [
    { month: 0, churnRate: 0.1 },
    { month: 1, churnRate: 0.15 },
    { month: 2, churnRate: 0.2 },
    { month: 3, churnRate: 0.18 },
    { month: 4, churnRate: 0.12 },
    { month: 5, churnRate: 0.1 },
    { month: 6, churnRate: 0.09 },
    { month: 7, churnRate: 0.1 },
    { month: 8, churnRate: 0.08 },
    { month: 9, churnRate: 0.05 },
    { month: 10, churnRate: 0.06 },
    { month: 11, churnRate: 0.04 },
    { month: 12, churnRate: 0.03 },
  ];
  return (
    <Box mx={-5}>
      <Image src="/images/cohort-churn-analysis-graph.png" width={'100%'} />
    </Box>
  );
};

export default ChurnGraph;
