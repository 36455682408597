import React from "react";

import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Progress,
  SimpleGrid,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const MarketingSpend = () => {
  return (
      <section>
        <Box mb={{ base: "10", md: "100" }}>
          <Box lineHeight={{ base: "7", xl: "42px" }} mb="5">
            <Text
              fontSize={{ base: "20px", md: "25px", lg: "36px" }}
              color="black.main"
              fontWeight="500"
            >
              Marketing spend
            </Text>
            <Text fontSize={{ base: "14px", lg: "16px" }} color="gray.main">
              So far this month you gained 1,500 installs, 1,200 free trials and
              800 new paying users. Right on!
            </Text>
          </Box>
          <Grid templateColumns="repeat(12, 1fr)" gap={5}>
            <GridItem colSpan={{ base: "12", lg: "8" }}>
              <Box
                bg="white"
                shadow={"box"}
                rounded={20}
                h={"100%"}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box p={{ base: "5", lg: "10" }}>
                  <Grid
                    alignItems={"center"}
                    templateColumns="repeat(12, 1fr)"
                    gap={{ base: "5", xl: "9" }}
                  >
                    <GridItem
                      colSpan={{ base: "12", lg: "3", xl: "3" }}
                      mr={{ xl: "5" }}
                    >
                      <Text
                        w={{ xl: "24" }}
                        color={"black.main"}
                        fontSize={{ base: "14px", lg: "16px" }}
                        fontWeight={700}
                        lineHeight={5}
                      >
                        Available Budget
                      </Text>
                      <Text
                        color={"black.main"}
                        fontSize={{ base: "18px", lg: "24px" }}
                        fontWeight={700}
                        lineHeight={7}
                        my={3}
                      >
                        $25,000
                      </Text>
                      <Text
                        color={"gray.main"}
                        fontSize={{ base: "12px", lg: "14px" }}
                        fontWeight={400}
                        lineHeight={4}
                      >
                        + additional $2,000 on premium partners
                      </Text>
                    </GridItem>
                    <GridItem colSpan={{ base: "12", lg: "9", xl: "9" }}>
                      <Flex flexWrap={"wrap"} gridGap={"30px"} rowGap={3}>
                        <Flex alignItems={"center"} gap={"10px"}>
                          <Box
                            w={5}
                            h={5}
                            rounded={"full"}
                            bg={"pink.main"}
                          ></Box>
                          <Box
                            color="gray.main"
                            fontSize={{ base: "12px", lg: "14px" }}
                          >
                            Used funds $5,500
                          </Box>
                        </Flex>
                        <Flex alignItems={"center"} gap={"10px"}>
                          <Box
                            w={5}
                            h={5}
                            rounded={"full"}
                            bg={"green.100"}
                          ></Box>
                          <Box
                            color="gray.main"
                            fontSize={{ base: "12px", lg: "14px" }}
                          >
                            Twelve rewards $50
                          </Box>
                        </Flex>
                        <Flex alignItems={"center"} gap={"10px"}>
                          <Box
                            w={5}
                            h={5}
                            rounded={"full"}
                            bg={"green.200"}
                          ></Box>
                          <Box
                            color="gray.main"
                            fontSize={{ base: "12px", lg: "14px" }}
                          >
                            Available funds $4,500
                          </Box>
                        </Flex>
                      </Flex>
                      <Box rounded={"full"} display={"flex"} mt="5">
                        <Box
                          bg={"pink.main"}
                          width={"55%"}
                          h="5"
                          borderLeftRadius="full"
                        ></Box>
                        <Box bg={"green.100"} width={"5%"} h="5"></Box>
                        <Box
                          bg={"green.200"}
                          width={"40%"}
                          h="5"
                          borderRightRadius="full"
                        ></Box>
                      </Box>
                    </GridItem>
                  </Grid>
                </Box>
                <Box
                  mt="auto"
                  bgGradient="linear(to-r,  white, green.100 )"
                  textAlign="center"
                  cursor="pointer"
                  borderBottomRadius={20}
                  borderTopRadius={0}
                  text="16px"
                  fontWeight={500}
                  w={"100%"}
                  p="4"
                >
                  + Get more funding
                </Box>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: "12", lg: "4" }}>
              <Box bg="black.main" p={{ base: "5", xl: "30px" }} rounded={10}>
                <Flex mb={"86px"} flexWrap={"wrap"} rowGap={4}>
                  <Image alt="" src="./images/svg/cc-twelve-logo.svg" />
                  <Spacer />
                  <Button
                    ml="auto"
                    display={"flex"}
                    gap="2"
                    bg="gray.100"
                    fontWeight={400}
                    rounded={"full"}
                    px="4"
                    py="1.5"
                    color="black.main"
                    fontSize={{ base: "12px", md: "14px" }}
                  >
                    Press to unlock
                    <Image src="./images/svg/icon-lock.svg" alt="" />
                  </Button>
                </Flex>
                <Text
                  mb="8"
                  color="white"
                  fontSize={{ base: "18px", md: "22px" }}
                  lineHeight={"29px"}
                  fontWeight={"500"}
                >
                  4567 **** **** 4501
                </Text>
                <Flex flexWrap={"wrap"} rowGap={4}>
                  <Text
                    color="white"
                    fontWeight={"400"}
                    fontSize={{ base: "16px", md: "20px" }}
                  >
                    04 / 23
                  </Text>
                  <Spacer />
                  <Image src="./images/svg/mastercard.svg" />
                </Flex>
              </Box>
            </GridItem>
          </Grid>
          <Box bg="white" shadow={"box"} rounded={20} mt="5">
            <TableContainer className="transection-table" overflow={"auto"}>
              <Table variant="simple">
                <TableCaption
                  color={"primary.main"}
                  p="5"
                  fontSize={{ base: "14px", lg: "16px" }}
                  fontWeight={500}
                  cursor={"pointer"}
                >
                  <Link textDecoration={"none"}>+ View more</Link>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>
                      <Box className="text-top">Transactions</Box>
                    </Th>
                    <Th>&nbsp;</Th>
                    <Th>&nbsp;</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Box display={"flex"} alignItems={"center"} gap="32px">
                        <Image
                          alt=""
                          src="./images/facebook-ads.png"
                          w="10"
                          h="10"
                        ></Image>
                        <Box>
                          <Text
                            fontSize={{ base: "14px", lg: "16px" }}
                            color="black.main"
                            fontWeight={700}
                            lineHeight={5}
                          >
                            Facebook Ads
                          </Text>
                          <Text
                            fontSize={{ base: "11px", lg: "12px" }}
                            color="gray.main"
                            lineHeight={"14px"}
                          >
                            12 minutes ago
                          </Text>
                        </Box>
                      </Box>
                    </Td>
                    <Td>&nbsp;</Td>
                    <Td>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap="32px"
                        justifyContent={"end"}
                      >
                        <Text
                          fontSize={{ base: "12px", lg: "14px" }}
                          color="black.main"
                          fontWeight={700}
                          lineHeight={5}
                        >
                          $1,000
                        </Text>
                        <Image alt="" src="./images/svg/icon-more.svg"></Image>
                      </Box>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Box display={"flex"} alignItems={"center"} gap="32px">
                        <Image
                          alt=""
                          src="./images/twik.png"
                          w="10"
                          h="10"
                        ></Image>
                        <Box>
                          <Text
                            fontSize={{ base: "14px", lg: "16px" }}
                            color="black.main"
                            fontWeight={700}
                            lineHeight={5}
                            display={"flex"}
                            alignItems={"center"}
                            gap="6px"
                          >
                            <Image
                              alt=""
                              src="./images/svg/icon-twik.svg"
                              w="4"
                              h="4"
                            ></Image>
                            <Text>Twik</Text>
                          </Text>
                          <Text
                            fontSize={{ base: "11px", lg: "12px" }}
                            color="gray.main"
                            lineHeight={"14px"}
                          >
                            1 day ago
                          </Text>
                        </Box>
                      </Box>
                    </Td>
                    <Td>
                      <Box
                        fontSize={{ base: "12px", lg: "14px" }}
                        color="black.main"
                        lineHeight={4}
                        display={"flex"}
                        fontWeight={400}
                      >
                        🎉 &nbsp;<Box fontWeight={700}> + $20 </Box>{" "}
                        &nbsp;Twelve rewards{" "}
                      </Box>
                    </Td>
                    <Td>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap="32px"
                        justifyContent={"end"}
                      >
                        <Text
                          fontSize={{ base: "12px", lg: "14px" }}
                          color="black.main"
                          fontWeight={700}
                          lineHeight={5}
                        >
                          $2,000
                        </Text>
                        <Image alt="" src="./images/svg/icon-more.svg"></Image>
                      </Box>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Box display={"flex"} alignItems={"center"} gap="32px">
                        <Image
                          alt=""
                          src="./images/pecan.png"
                          w="10"
                          h="10"
                        ></Image>
                        <Box>
                          <Text
                            fontSize={{ base: "14px", lg: "16px" }}
                            color="black.main"
                            fontWeight={700}
                            lineHeight={5}
                            display={"flex"}
                            alignItems={"center"}
                            gap="6px"
                          >
                            <Image
                              alt=""
                              src="./images/svg/icon-twik.svg"
                              w="4"
                              h="4"
                            ></Image>
                            <Text>Pecan.ai</Text>
                          </Text>
                          <Text
                            fontSize={{ base: "11px", lg: "12px" }}
                            color="gray.main"
                            lineHeight={"14px"}
                          >
                            1 day ago
                          </Text>
                        </Box>
                      </Box>
                    </Td>
                    <Td>
                      <Box
                        fontSize={{ base: "12px", lg: "14px" }}
                        color="black.main"
                        lineHeight={4}
                        display={"flex"}
                        fontWeight={400}
                      >
                        🎉 &nbsp;<Box fontWeight={700}> + $5 </Box> &nbsp;Twelve
                        rewards{" "}
                      </Box>
                    </Td>
                    <Td>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap="32px"
                        justifyContent={"end"}
                      >
                        <Text
                          fontSize={{ base: "12px", lg: "14px" }}
                          color="black.main"
                          fontWeight={700}
                          lineHeight={5}
                        >
                          $500
                        </Text>
                        <Image alt="" src="./images/svg/icon-more.svg"></Image>
                      </Box>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Box display={"flex"} alignItems={"center"} gap="32px">
                        <Image
                          alt=""
                          src="./images/google-ads.png"
                          w="10"
                          h="10"
                        ></Image>
                        <Box>
                          <Text
                            fontSize={{ base: "14px", lg: "16px" }}
                            color="black.main"
                            fontWeight={700}
                            lineHeight={5}
                          >
                            Google Ads
                          </Text>
                          <Text
                            fontSize={{ base: "11px", lg: "12px" }}
                            color="gray.main"
                            lineHeight={"14px"}
                          >
                            2 weeks ago
                          </Text>
                        </Box>
                      </Box>
                    </Td>
                    <Td>&nbsp;</Td>
                    <Td>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        gap="32px"
                        justifyContent={"end"}
                      >
                        <Text
                          fontSize={{ base: "12px", lg: "14px" }}
                          color="black.main"
                          fontWeight={700}
                          lineHeight={5}
                        >
                          $2,000
                        </Text>
                        <Image alt="" src="./images/svg/icon-more.svg"></Image>
                      </Box>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </section>
  );
};

export default MarketingSpend;
