import { Element } from 'react-scroll';
import { Box } from '@chakra-ui/react';
import Finance from './Finance/Finance';
import Customers from './Customers/Customers';
import General from './General/General';

function PerformanceDemo() {
  return (
    <Box pb={450}>
      <Element name={'finance'}>
        <Finance />
      </Element>
      <Element name={'customers'}>
        <Customers />
      </Element>
      <Element name={'general'}>
        <General />
      </Element>
    </Box>
  );
}

export default PerformanceDemo;
