import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import SensitiveAnalysisGraph from "./SensitiveAnalysisGraph";
import TwelveFinance from "./TwelveFinance";

const Finance = () => {
  return (
    <>
      <section>
        <Box lineHeight={{ base: "7" }}>
          <Text
            fontSize={{ base: "20px", md: "25px", lg: "36px" }}
            color="black.main"
            fontWeight="500"
          >
            Finance
          </Text>
          <Text
            fontSize={{ base: "14px", lg: "16px" }}
            color="black.main"
            paddingTop={"7px"}
          >
            Something about finance
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(3, 1fr)"
          }}
          gap={6}
          paddingTop={"12px"}
        >
          <GridItem
            w="100%"
            bg="white"
            rounded={"20px"}
            padding={"24px 24px 18px 24px"}
            shadow={"box"}
          >
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={{ base: "14px", lg: "16px" }}
              paddingBottom={"12px"}
              textTransform={"uppercase"}
            >
              Burn Rate
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"5px"}
            >
              $75,000
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem
            w="100%"
            bg="white"
            rounded={"20px"}
            padding={"24px 24px 18px 24px"}
            shadow={"box"}
          >
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Text
                  color={"gray.main"}
                  fontWeight={500}
                  fontSize={{ base: "14px", lg: "16px" }}
                  paddingBottom={"12px"}
                  textTransform={"uppercase"}
                >
                  Cash in the bank
                </Text>
                <Text
                  color={"gray.200"}
                  fontWeight={700}
                  fontSize={{ base: "18px", lg: "24px" }}
                  paddingBottom={"5px"}
                >
                  $1,050,000
                </Text>
                <Text
                  color={"gray.200"}
                  fontWeight={400}
                  fontSize={12}
                  display={"flex"}
                  gap={2}
                >
                  <Text color={"green.200"}> +1% </Text> since last month
                </Text>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
                gap={1}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                >
                  <Text fontSize={12} fontWeight={400} color={"black.main"}>
                    via
                  </Text>
                  <Image alt="" src="/images/plan.png" />
                </Box>
                <Box>
                  <Image alt="" src="/images/bank-1.png" />
                  <Image alt="" src="/images/bank-2.png" marginTop={"-10px"} />
                  <Image alt="" src="/images/bank-3.png" marginTop={"-10px"} />
                </Box>
              </Box>
            </Box>
          </GridItem>
          <GridItem
            w="100%"
            bg="white"
            rounded={"20px"}
            padding={"24px 24px 18px 24px"}
            shadow={"box"}
          >
            <Box>
              <Text
                color={"gray.main"}
                fontWeight={500}
                fontSize={{ base: "14px", lg: "16px" }}
                paddingBottom={"12px"}
                textTransform={"uppercase"}
              >
                Runway
              </Text>
              <Text
                color={"gray.200"}
                fontWeight={700}
                fontSize={{ base: "18px", lg: "24px" }}
                paddingBottom={"5px"}
              >
                9{" "}
                <Text
                  as="span"
                  fontSize={12}
                  fontWeight={400}
                  color={"gray.main"}
                >
                  (month)
                </Text>
              </Text>
              <Text
                color={"gray.200"}
                fontWeight={400}
                fontSize={12}
                display={"flex"}
                gap={2}
              >
                <Text color={"pink.main"}> -6.50% </Text> since last month
              </Text>
            </Box>
          </GridItem>
        </Grid>
        <Box marginTop={"20px"}>
          <TwelveFinance />
        </Box>
        <SensitiveAnalysisGraph />
      </section>
    </>
  );
};

export default Finance;
