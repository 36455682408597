import React from "react";
import { Element } from 'react-scroll';
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Progress,
  SimpleGrid,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  flexbox
} from "@chakra-ui/react";

const PartnerStoreDemo = () => {
  const suggestions = {
    data: [
      {
        img: "/images/madgicx.png",
        icon: "/images/svg/icon-twik.svg",
        name: "Madgicx",
        description: "☁️ The Ecom Ad Cloud",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/twik.png",
        icon: "/images/svg/icon-twik.svg",
        name: "Twik",
        description: "Autonomous eCommerce Personalization",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/app-radar.png",
        icon: "/images/svg/icon-twik.svg",
        name: "App Radar",
        description: "App Store Optimization & ASA Tools and Services",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/tiktok.png",

        name: "TikTok",
        description:
          "Get in front of customers when they're searching for businesses like yours on Google Search and Maps",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/facebook-ads.png",

        name: "Facebook Ads",
        description: "Social Media Advertising For Your Business",
        learn: "Learn more",
        link: "#"
      }
    ]
  };

  const premium = {
    data: [
      {
        img: "/images/madgicx.png",
        icon: "/images/svg/icon-twik.svg",
        name: "Madgicx",
        description: "☁️ The Ecom Ad Cloud",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/twik.png",
        icon: "/images/svg/icon-twik.svg",
        name: "Twik",
        description: "Autonomous eCommerce Personalization",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/app-radar.png",
        icon: "/images/svg/icon-twik.svg",
        name: "App Radar",
        description: "App Store Optimization & ASA Tools and Services",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/appalgo.svg",
        icon: "/images/svg/icon-twik.svg",
        name: "Appalgo",
        description:
          "appalgo helps mobile app marketers navigate the stormy waters of user acquisition, and optimize their media spend.",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/arpeely.jpg",
        icon: "/images/svg/icon-twik.svg",
        name: "Arpeely",
        description:
          "Arpeely provides autonomous media engine, that targets, re-targets, self-adapts and generates insights to zero-down on the most valuable traffic, users and LTV.",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/go.jpeg",
        icon: "/images/svg/icon-twik.svg",
        name: "Go",
        description:
          "Go Digital Marketing is an award-winning digital agency, focusing on result based advertising.",
        learn: "Learn more",
        link: "#"
      }
    ]
  };

  const approved = {
    data: [
      {
        img: "/images/madgicx.png",
        icon: "/images/svg/icon-twik.svg",
        name: "Madgicx",
        description: "☁️ The Ecom Ad Cloud",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/twik.png",
        icon: "/images/svg/icon-twik.svg",
        name: "Twik",
        description: "Autonomous eCommerce Personalization",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/app-radar.png",
        icon: "/images/svg/icon-twik.svg",
        name: "App Radar",
        description: "App Store Optimization & ASA Tools and Services",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/iron-source.png",

        name: "IronSource",
        description: "Turning Apps Into Scalable Businesses",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/facebook-ads.png",

        name: "Facebook Ads",
        description: "Social Media Advertising For Your Business",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/tiktok.png",
        name: "TikTok",
        description: "Make Your Day - TikTok",
        learn: "Learn more",
        link: "#"
      },
      {
        img: "/images/apple-search.png",
        name: "Apple Search Ads",
        description: "Promote your app where it matters most.",
        learn: "Learn more",
        link: "#"
      }
    ]
  };
  return (
    <Box pb={850}>
      <section>
        <Element name="suggested">
        <Box mb="5">
          <Text
            fontSize={{ base: "20px", md: "25px", lg: "36px" }}
            lineHeight={{ base: "7", xl: "42px" }}
            color="black.main"
            fontWeight="500"
          >
            Suggested for you
          </Text>
          <Text fontSize={"14px"} color="black.main">
            Explore the most relevant suggestions based on your goals and needs.
          </Text>
        </Box>
      
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)"
          }}
          gap={"20px"}
        >
          {suggestions.data.map((value, index) => {
            return (
              <Box key={`madgicx-${index}`}>
                <GridItem
                  w="100%"
                  h={"190px"}
                  bg="white"
                  rounded={"20px"}
                  padding={"14px 16px"}
                  shadow={"box"}
                >
                  <Flex
                    direction={"column"}
                    justifyContent={"space-between"}
                    h={"100%"}
                  >
                    <Box>
                      <Image src={value.img} />
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"start"}
                        gap={"4px"}
                        paddingTop={"8px"}
                        paddingBottom={"3px"}
                      >
                        <Image src={value.icon} />
                        <Text
                          color={"black.main"}
                          fontWeight={700}
                          fontSize={{ base: "14px", lg: "16px" }}
                        >
                          {value.name}
                        </Text>
                      </Box>
                      <Text
                        fontWeight={400}
                        fontSize={"12px"}
                        color={"gray.main"}
                      >
                        {value.description}
                      </Text>
                    </Box>

                    <Link href={value.link}>
                      <Text
                        fontWeight={700}
                        fontSize={"14px"}
                        color="primary.main"
                        cursor={"pointer"}
                      >
                        {value.learn}
                      </Text>
                    </Link>
                  </Flex>
                </GridItem>
              </Box>
            );
          })}
        </Grid>
        </Element>
        <Element name="premium">
        <Box
          marginTop={{ base: "50px", md: "75px", lg: "95px" }}
          marginBottom={"42px"}
        >
          <Flex justifyContent={"start"} alignItems={"center"} gap={"10px"}>
            <Image
              src="/images/svg/icon-twik.svg"
              w={"32px"}
              h={"32px"}
            />
            <Text
              fontSize={{ base: "20px", md: "25px", lg: "34px" }}
              color="black.main"
              fontWeight="500"
            >
              Premium partners
            </Text>
          </Flex>
          <Text fontSize={{ base: "14px", lg: "16px" }} color="black.main">
            Something about premium partners
          </Text>
        </Box>
        
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)"
          }}
          gap={"20px"}
        >
          {premium.data.map((value, index) => (
            <>
              <Box key={`madgicx-${index}`}>
                <GridItem
                  w="100%"
                  h={"190px"}
                  bg="white"
                  rounded={"20px"}
                  padding={"14px 16px"}
                  shadow={"box"}
                >
                  <Flex
                    direction={"column"}
                    justifyContent={"space-between"}
                    h={"100%"}
                  >
                    <Box>
                      <Image src={value.img} />
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"start"}
                        gap={"4px"}
                        paddingTop={"8px"}
                        paddingBottom={"3px"}
                      >
                        <Image src={value.icon} />
                        <Text
                          color={"black.main"}
                          fontWeight={700}
                          fontSize={{ base: "14px", lg: "16px" }}
                        >
                          {value.name}
                        </Text>
                      </Box>
                      <Text
                        fontWeight={400}
                        fontSize={"12px"}
                        color={"gray.main"}
                      >
                        {value.description}
                      </Text>
                    </Box>
                    <Link href={value.link}>
                      <Text
                        fontWeight={700}
                        fontSize={"14px"}
                        color="primary.main"
                        cursor={"pointer"}
                      >
                        {value.learn}
                      </Text>
                    </Link>
                  </Flex>
                </GridItem>
              </Box>
            </>
          ))}
        </Grid>
        </Element>
        <Element name="approved">
        <Box
          marginTop={{ base: "50px", md: "75px", lg: "95px" }}
          marginBottom={"20px"}
        >
          <Text
            fontSize={{ base: "20px", md: "25px", lg: "34px" }}
            color="black.main"
            fontWeight="500"
          >
            All approved vendors
          </Text>
          <Text fontSize={{ base: "14px", lg: "16px" }} color="black.main">
            Something about all our partners
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            xl: "repeat(4, 1fr)"
          }}
          gap={"20px"}
        >
          {approved.data.map((value, index) => {
            return (
              <Box key={`madgicx-${index}`}>
                <GridItem
                  w="100%"
                  h={"190px"}
                  bg="white"
                  rounded={"20px"}
                  padding={"14px 16px"}
                  shadow={"box"}
                >
                  <Flex
                    direction={"column"}
                    justifyContent={"space-between"}
                    h={"100%"}
                  >
                    <Box>
                      <Image src={value.img} />
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"start"}
                        gap={"4px"}
                        paddingTop={"8px"}
                        paddingBottom={"3px"}
                      >
                        <Image src={value.icon} />
                        <Text
                          color={"black.main"}
                          fontWeight={700}
                          fontSize={{ base: "14px", lg: "16px" }}
                        >
                          {value.name}
                        </Text>
                      </Box>
                      <Text
                        fontWeight={400}
                        fontSize={"12px"}
                        color={"gray.main"}
                      >
                        {value.description}
                      </Text>
                    </Box>

                    <Link href={value.link}>
                      <Text
                        fontWeight={700}
                        fontSize={"14px"}
                        color="primary.main"
                        cursor={"pointer"}
                      >
                        {value.learn}
                      </Text>
                    </Link>
                  </Flex>
                </GridItem>
              </Box>
            );
          })}
        </Grid>
        </Element>
      </section>
    </Box>
  );
};

export default PartnerStoreDemo;
