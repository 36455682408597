import React, { useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {
  Box,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from "@chakra-ui/react";

const SensitiveAnalysisGraph = () => {
  const data = [
    { month: "Jan", Likely_Scenario: 0, green: 0, pink: 0 },
    { month: "Feb", Likely_Scenario: 0, green: 0, pink: 0 },
    { month: "Mar", Likely_Scenario: 0, green: 0, pink: 0 },
    { month: "Apr", Likely_Scenario: 0, green: 0, pink: 0 },
    { month: "May", Likely_Scenario: 0, green: 0, pink: 0 },
    { month: "June", Likely_Scenario: 200, green: 250, pink: 180 },
    { month: "July", Likely_Scenario: 205, green: 260, pink: 170 },
    { month: "Aug", Likely_Scenario: 210, green: 270, pink: 160 },
    { month: "Sep", Likely_Scenario: 220, green: 280, pink: 150 },
    { month: "Oct", Likely_Scenario: 230, green: 290, pink: 140 },
    { month: "Nov", Likely_Scenario: 240, green: 300, pink: 130 },
    { month: "Dec", Likely_Scenario: 250, green: 320, pink: 120 }
  ];

  const [isMrrCacOpen, setIsMrrCacOpen] = useState(false);
  const [isCacOptionsOpen, setIsCacOptionsOpen] = useState(false);
  const [selectedMrrCacOption, setSelectedMrrCacOption] = useState("");
  const [selectedCacOption, setSelectedCacOption] = useState("");

  const mrrCacOptions = ["MRR"];
  const cacOptions = ["ARPU", "30D retention"];
  const toggleMrrCacDropdown = () => {
    setIsMrrCacOpen(!isMrrCacOpen);
  };

  const toggleCacOptionsDropdown = () => {
    setIsCacOptionsOpen(!isCacOptionsOpen);
  };
  const handleMrrCacOptionSelect = (option) => {
    setSelectedMrrCacOption(option);
    setIsMrrCacOpen(false);
  };

  const handleCacOptionSelect = (option) => {
    setSelectedCacOption(option);
    setIsCacOptionsOpen(false);
  };
  return (
    <>
      <Box
        backgroundColor="#fff"
        shadow={"box"}
        padding={{ base: "14px 16px", lg: "20px 24px" }}
        marginTop={"20px"}
        rounded="md"
      >
        <Box marginBottom={"30px"}>
          <Text
            fontSize={{ base: "14px", lg: "16px" }}
            color={"gray.200"}
            fontWeight={"700"}
            className="mb-1"
          >
            SENSITIVITY ANALYSIS
          </Text>
          <Text color={"gray.main"} fontSize={"14px"} fontWeight={"400"}>
            See how performance indicator is predicted to develop based on
            different scenarios
          </Text>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"start"}
          gap={6}
        >
          <Menu isLazy>
            <MenuButton
              variant="outline"
              colorScheme="gray"
              onClick={toggleMrrCacDropdown}
              color={"gray.200"}
              fontSize={{ base: "12px", lg: "16px" }}
              fontWeight={500}
              rightIcon={
                isMrrCacOpen ? (
                  <Image src="/images/svg/icon-arrow-down.svg" />
                ) : (
                  "up"
                )
              }
            >
              {" "}
              {selectedMrrCacOption || (
                <Box display={"flex"} justifyContent={"center"} gap={"10px"}>
                  {" "}
                  <Text>MRR</Text>{" "}
                  <Image src="/images/svg/icon-arrow-down.svg" />
                </Box>
              )}
            </MenuButton>
            <MenuList
              border="1px solid #E0E8F0"
              rounded="20px"
              px="10px"
              py={"14px"}
              sx={{ maxW: "100px)" }}
              fontSize={{ base: "12px", lg: "16px" }}
            >
              {mrrCacOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleMrrCacOptionSelect(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Text fontSize={{ base: "14px", lg: "16px" }} color={"gray.main"}>
            vs
          </Text>
          <Menu isLazy>
            <MenuButton
              fontWeight={500}
              fontSize={{ base: "14px", lg: "16px" }}
              color={"gray.200"}
              variant="outline"
              colorScheme="gray"
              onClick={toggleCacOptionsDropdown}
              rightIcon={isCacOptionsOpen ? "up" : "down"}
            >
              {selectedCacOption || (
                <Box display={"flex"} justifyContent={"center"} gap={"10px"}>
                  {" "}
                  <Text>CAC</Text>{" "}
                  <Image src="/images/svg/icon-arrow-down.svg" />
                </Box>
              )}
            </MenuButton>
            <MenuList
              border="1px solid #E0E8F0"
              rounded="20px"
              px="10px"
              py={"14px"}
              fontSize={{ base: "12px", lg: "16px" }}
            >
              {cacOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleCacOptionSelect(option)}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
        <ResponsiveContainer width={"99%"} height={294}>
          <LineChart data={data}>
            <CartesianGrid stroke="#E0E8F0" vertical={false} />c
            <XAxis
              dataKey="month"
              stroke="#E0E8F0"
              height={80}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                dy: 30,
                fontSize: 14
              }}
            />
            <YAxis
              type="number"
              domain={[0, 400]}
              axisLine={false}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: 14
              }}
            />
            <Tooltip />
            <Legend
              iconType="circle"
              layout="horizontal"
              verticalAlign="top"
              align="end"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            <Line
              type="monotone"
              dot={false}
              dataKey="Likely_Scenario"
              stroke="#2084F9"
              strokeWidth={4}
              name="Likely Scenario"
            />
            <Line
              type="monotone"
              dot={false}
              strokeWidth={4}
              dataKey="green"
              stroke="#10DC49"
              name="CAC - 10%"
            />
            <Line
              type="monotone"
              strokeWidth={4}
              dot={false}
              dataKey="pink"
              stroke="#F322F8"
              name="CAC + 10%"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default SensitiveAnalysisGraph;
