import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart
} from "recharts";
const General = () => {
  const data = [
    { month: "Jan", actual: 1, projections: 1 },
    { month: "Feb", actual: 1, projections: 1 },
    { month: "Mar", actual: 3, projections: 4 },
    { month: "Apr", actual: 3, projections: 4 },
    { month: "May", actual: 5, projections: 5 },
    { month: "June", actual: 5, projections: 6 },
    { month: "July", actual: 0, projections: 8 },
    { month: "Aug", actual: 0, projections: 9 },
    { month: "Sep", actual: 0, projections: 12 },
    { month: "Oct", actual: 0, projections: 14 },
    { month: "Nov", actual: 13, projections: 14 },
    { month: "Dec", actual: 14, projections: 16 }
  ];
  const formatYAxisTick = (value) => {
    return `$${value}k`;
  };
  return (
    <>
      <section>
        <Box lineHeight={{ base: "7" }} mb="5" mt={"80px"}>
          <Text
            fontSize={{ base: "20px", md: "25px", lg: "36px" }}
            color="black.main"
            fontWeight="500"
          >
            General
          </Text>
          <Text
            fontSize={{ base: "14px", lg: "16px" }}
            color="gray.main"
            paddingTop={"10px"}
          >
            Top line performance
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(3, 1fr)"
          }}
          gap={6}
        >
          <GridItem
            w="100%"
            bg="white"
            rounded={"20px"}
            padding={"24px"}
            // border="2px"
            borderWidth="3px"
            className="border-3"
            borderColor={"primary.main"}
          >
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"20px"}
            >
              MRR
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $45,000
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={"14px"}
              paddingBottom={"12px"}
            >
              MRR Growth
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $3,375
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"green.200"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={{ base: "14px", lg: "16px" }}
              paddingBottom={"20px"}
            >
              ARR
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $540,000
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"green.200"}> -5% </Text> since last month
            </Text>
          </GridItem>
        </Grid>
        <Text
          paddingTop={"20px"}
          paddingBottom={"10px"}
          color={"gray.main"}
          fontWeight={400}
          fontSize={16}
        >
          Unit economics performance
        </Text>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(6, 1fr)"
          }}
          gap={6}
        >
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={14}
              paddingBottom={"20px"}
            >
              LTV
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $307
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"green.200"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={14}
              paddingBottom={"20px"}
            >
              ARPU
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $29
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={14}
              paddingBottom={"20px"}
            >
              ARPPU
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $42
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={14}
              paddingBottom={"20px"}
            >
              Marketing Spend
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $50,000
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={14}
              paddingBottom={"20px"}
            >
              CPA
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $88
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
          <GridItem w="100%" bg="white" rounded={"20px"} padding={"24px"}>
            <Text
              color={"gray.main"}
              fontWeight={500}
              fontSize={14}
              paddingBottom={"20px"}
            >
              Blended CAC
            </Text>
            <Text
              color={"gray.main"}
              fontWeight={700}
              fontSize={{ base: "18px", lg: "24px" }}
              paddingBottom={"10px"}
            >
              $101
            </Text>
            <Text
              color={"gray.200"}
              fontWeight={400}
              fontSize={12}
              display={"flex"}
              gap={2}
            >
              <Text color={"pink.main"}> -5% </Text> since last month
            </Text>
          </GridItem>
        </Grid>

        <Text
          fontSize={"16px"}
          color="black.main"
          fontWeight="700"
          paddingTop={"34px"}
        >
          MRR
        </Text>

        <ResponsiveContainer width={"99%"} height={390}>
          <BarChart data={data}>
            <CartesianGrid stroke="#E0E8F0" vertical={false} />
            <XAxis
              dataKey="month"
              stroke="#E0E8F0"
              height={80}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                dy: 30,
                fontSize: 12
              }}
            />
            <YAxis
              axisLine={false}
              tickFormatter={formatYAxisTick}
              tick={{
                fill: "#141320",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: 12
              }}
            />
            {/* <Tooltip /> */}
            <Legend
              layout="horizontal"
              iconType="circle"
              verticalAlign="top"
              align="end"
              wrapperStyle={{ paddingBottom: "20px" }}
            />
            <Bar dataKey="actual" stackId="stack" fill="#2084F9" barSize={40} />
            <Bar
              dataKey="projections"
              stackId="stack"
              fill="#E0E8F0"
              barSize={40}
            />
          </BarChart>
        </ResponsiveContainer>
      </section>
    </>
  );
};

export default General;
